import React from 'react';
import PropTypes from 'prop-types';
import { Loader, Select, TextInput } from '@mantine/core';
import AssociationSelectInput from '../content/associations/AssociationSelectInput';
import CustomSelect from './CustomSelect';

const FilterInput = ({
  type,
  label,
  value,
  untrackedValue,
  onChange,
  data,
  loading,
  disabled,
  ...rest
}) =>
  type === 'association' ? (
    <AssociationSelectInput
      autoPopulate
      data={data}
      disabled={disabled}
      label={label}
      onChange={onChange}
      rightSection={
        !disabled && loading ? <Loader color="dark" size={16} /> : null
      }
      value={value}
      {...rest}
    />
  ) : type === 'select' ? (
    <Select
      data={data}
      disabled={disabled}
      label={label}
      onChange={onChange}
      rightSection={
        !disabled && loading ? <Loader color="dark" size={16} /> : null
      }
      value={value}
      withCheckIcon={false}
      {...rest}
    />
  ) : type === 'custom-select' ? (
    <CustomSelect
      data={data}
      disabled={disabled}
      label={label}
      onChange={onChange}
      rightSection={
        !disabled && loading ? <Loader color="dark" size={16} /> : null
      }
      value={value ?? ''}
      {...rest}
    />
  ) : (
    <TextInput
      disabled={disabled}
      label={label}
      onChange={(e) => onChange(e.currentTarget.value)}
      placeholder="Filter by registrant"
      rightSection={
        !disabled && loading ? <Loader color="dark" size={16} /> : null
      }
      value={untrackedValue ? undefined : value || ''}
      {...rest}
    />
  );

FilterInput.propTypes = {
  data: PropTypes.array,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string,
  untrackedValue: PropTypes.bool,
  value: PropTypes.string
};

export default FilterInput;
