import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ActionIcon,
  Alert,
  Badge,
  Button,
  Checkbox,
  Divider,
  List,
  Loader,
  Menu,
  ScrollArea,
  Skeleton,
  Table,
  Text,
  Tooltip
} from '@mantine/core';
import {
  ArrowDown,
  ArrowUp,
  ChevronDown,
  ChevronUp,
  Dots,
  Plus,
  Refresh,
  X
} from 'tabler-icons-react';
import AppStack from './AppStack';
import AppFlexbox from './AppFlexbox';
import AppText from './AppText';
import AppImage from './AppImage';
import MenuContent from './MenuContent';
import FilterInput from './FilterInput';
import ActionableIcon from './ActionableIcon';
import classes from '../../styles/nestedStyles.module.css';
import { useMediaQueryIndex, useOnScreen } from '../../helpers/hooks';
import { singularPluralFormat } from '../../helpers/format';
import { getResponsiveStyle as rs } from '../../helpers/styles';

const isTableRenderSameAsBefore = (prevProps, nextProps) =>
  (!prevProps.selectedRows ||
    prevProps.selectedRows.length === nextProps.selectedRows.length) &&
  prevProps.rows.length === nextProps.rows.length &&
  prevProps.lastUpdated === nextProps.lastUpdated &&
  prevProps.filtersLoading === nextProps.filtersLoading &&
  prevProps.rows?.every((f) =>
    nextProps.rows?.some(
      (p) =>
        f.key === p.key &&
        f.columns.every(
          (c) =>
            !!p.columns.find(
              (pc) =>
                pc.key === c.key &&
                pc.label === c.label &&
                pc.subLabel === c.subLabel
            )
        )
    )
  ) &&
  (!prevProps.alerts ||
    prevProps.alerts?.every((f) =>
      nextProps.alerts?.some((p) => f.key === p.key && f.message === p.message)
    )) &&
  prevProps.isLoading === nextProps.isLoading &&
  prevProps.sortBy === nextProps.sortBy &&
  prevProps.isDescendingSort === nextProps.isDescendingSort &&
  (!prevProps.filters ||
    (prevProps.filters?.length === nextProps.filters?.length &&
      prevProps.filters?.every((f) =>
        nextProps.filters?.some(
          (p) =>
            f.key === p.key &&
            f.value === p.value &&
            f.data?.length === p.data?.length
        )
      ))) &&
  prevProps.columns.length === nextProps.columns.length;

const TableView = React.memo(
  ({
    alerts,
    columns,
    rows,
    emptyMessage,
    emptyActionButton,
    isLoading,
    onAction,
    filters,
    filtersLoading,
    sortBy,
    sortFilter,
    onChangeSortBy,
    isDescendingSort,
    onRefresh,
    tableTitle,
    totalCount,
    onFetchMore,
    noSort,
    onSelect,
    selectedRows,
    lastUpdated
  }) => {
    const mqIndex = useMediaQueryIndex();
    const endOfPageLoader = useRef(null);
    const endOfPageLoaderMobile = useRef(null);
    const isEndOfPage = useOnScreen(endOfPageLoader);
    const isEndOfPageMobile = useOnScreen(endOfPageLoaderMobile);
    const [alertState, setAlertState] = useState({
      initial: [],
      current: []
    });
    const [showMobileFilters, setShowMobileFilters] = useState(false);
    const TOTAL_ITEM_COUNT = totalCount ?? rows.length;
    const sortByColumnIndex = columns.findIndex((v) => v.value === sortBy);
    const showEndPageLoader =
      !isLoading && TOTAL_ITEM_COUNT > 0 && rows.length < TOTAL_ITEM_COUNT;

    const rowsSelected = selectedRows || [];
    const allRowsSelected =
      rows.length > 0 && rowsSelected?.length === rows.length;

    const onSelecStyles = onSelect ? { cursor: 'pointer' } : {};

    useEffect(() => {
      if (!isLoading && alerts) {
        if (
          alerts.length !== alertState.initial.length ||
          !alerts.every((a) =>
            alertState.initial.some(
              (s) => s.key === a.key && s.message === a.message
            )
          )
        ) {
          setAlertState({
            initial: alerts,
            current: alerts
          });
        }
      }
    }, [alerts, isLoading]);

    useEffect(() => {
      if (isLoading) {
        setAlertState({
          initial: [],
          current: []
        });
      }
    }, [isLoading]);

    useEffect(() => {
      if (showEndPageLoader && (isEndOfPage || isEndOfPageMobile)) {
        onFetchMore();
      }
    }, [isEndOfPage, isEndOfPageMobile]);

    const sortRows = (a, b) => {
      if (sortByColumnIndex !== -1) {
        const sortValueA =
          a.columns[sortByColumnIndex].sortValue !== undefined
            ? a.columns[sortByColumnIndex].sortValue
            : a.columns[sortByColumnIndex].label || '';
        const sortValueB =
          b.columns[sortByColumnIndex].sortValue !== undefined
            ? b.columns[sortByColumnIndex].sortValue
            : b.columns[sortByColumnIndex].label || '';

        if (
          a.columns[sortByColumnIndex].sortValueType === 'date' ||
          a.columns[sortByColumnIndex].sortValueType === 'number'
        ) {
          return isDescendingSort
            ? sortValueB - sortValueA
            : sortValueA - sortValueB;
        }

        return isDescendingSort
          ? sortValueB.localeCompare(sortValueA)
          : sortValueA.localeCompare(sortValueB);
      }

      return isDescendingSort
        ? (b.columns[0]?.label || '').localeCompare(a.columns[0]?.label || '')
        : (a.columns[0]?.label || '').localeCompare(b.columns[0]?.label || '');
    };

    const onSelectTableRow = (rowKey) => {
      if (onSelect) {
        if (rowsSelected.includes(rowKey)) {
          onSelect([...rowsSelected.filter((f) => f !== rowKey)]);
        }
        else {
          onSelect([...rowsSelected, rowKey]);
        }
      }
    };

    const renderTableColumnInfo = (
      rowKey,
      c,
      index,
      isMobile = false,
      hideIcon = false
    ) => (
      <AppFlexbox style={{ alignItems: 'center', gap: 10 }}>
        {onSelect && index === 0 ? (
          <Checkbox
            checked={rowsSelected.includes(rowKey)}
            onChange={() => {}}
            styles={{ input: { cursor: 'pointer' } }}
          />
        ) : (
          isMobile && <AppStack style={{ width: 20 }} />
        )}
        {!hideIcon &&
          (c.src ? (
            <AppStack>
              <AppImage height={40} src={c.src} width={40} />{' '}
            </AppStack>
          ) : (
            !!c.icon && <AppStack>{c.icon}</AppStack>
          ))}
        <AppStack
          style={{
            gap: 0,
            alignItems: 'start'
          }}
        >
          <AppText
            className={c.onClick ? classes['hover-text-action'] : null}
            color={c.color || '#000'}
            onClick={c.onClick}
            style={{
              fontSize: 14,
              lineHeight: '18px',
              ...(isMobile
                ? {
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    lineClamp: '2',
                    WebkitBoxOrient: 'vertical'
                  }
                : { maxWidth: 400 })
            }}
            weight={c.weight}
          >
            {c.label}
          </AppText>
          {c.subLabel && (
            <AppText
              className={
                c.onSubLabelClick ? classes['hover-text-action'] : null
              }
              color={c.subLabelColor || 'grey'}
              onClick={c.onSubLabelClick}
              style={{
                fontSize: 14,
                lineHeight: '18px',
                ...(isMobile
                  ? {
                      display: '-webkit-box',
                      WebkitLineClamp: '2',
                      lineClamp: '2',
                      WebkitBoxOrient: 'vertical'
                    }
                  : { maxWidth: 400 })
              }}
            >
              {c.subLabel}
            </AppText>
          )}
        </AppStack>
      </AppFlexbox>
    );

    const renderTableRowMenuActions = (r) =>
      r.actions.length > 0 && (
        <MenuContent
          control={
            <ActionIcon color="lightgrey" radius="xl" variant="subtle">
              <Dots color="#000" />
            </ActionIcon>
          }
        >
          {r.actions.map((a) => (
            <Menu.Item
              key={a.value}
              onClick={(e) => {
                e.preventDefault();
                onAction(a.value, r);
              }}
            >
              {a.label}
            </Menu.Item>
          ))}
        </MenuContent>
      );

    return (
      <AppStack
        style={{
          gap: 0,
          flex: 1,
          height: '100%'
        }}
      >
        {(tableTitle || filters?.length > 0) && (
          <AppStack hiddenFrom="sm" style={{ padding: 10, gap: 0 }}>
            {tableTitle && (
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <AppText style={{ color: 'grey', fontSize: 14 }} weight={500}>
                  {tableTitle}
                </AppText>
                <AppStack>
                  <ActionableIcon
                    color="dodgerblue"
                    disabled={isLoading}
                    onClick={onRefresh}
                    radius="xl"
                    style={{ background: '#DEE2E6' }}
                    variant="subtle"
                  >
                    <Refresh />
                  </ActionableIcon>
                </AppStack>
              </AppFlexbox>
            )}

            {filters &&
              filters.length > 0 &&
              (filters.length === 1 ? (
                <FilterInput
                  disabled={isLoading}
                  loading={filtersLoading}
                  style={{ flex: 1 }}
                  {...filters[0]}
                />
              ) : (
                filters.length > 1 && (
                  <>
                    <AppFlexbox
                      onClick={() => setShowMobileFilters(!showMobileFilters)}
                      style={{
                        justifyContent: 'space-between',
                        paddingTop: 10
                      }}
                    >
                      <AppText weight={500}>Filters</AppText>
                      {showMobileFilters ? <ChevronUp /> : <ChevronDown />}
                    </AppFlexbox>
                    {showMobileFilters && (
                      <>
                        <Divider style={{ marginTop: 5 }} />
                        {[...filters].map((filter) => (
                          <FilterInput
                            key={filter.key}
                            disabled={isLoading}
                            loading={filtersLoading}
                            style={{ flex: 1 }}
                            {...filter}
                          />
                        ))}
                      </>
                    )}
                  </>
                )
              ))}
          </AppStack>
        )}

        <AppFlexbox
          style={{
            padding: 10,
            paddingTop: filters ? 2 : 10,
            gap: 10,
            alignItems: 'end'
          }}
          visibleFrom="sm"
        >
          <AppFlexbox
            style={{ gap: 10, flex: 1 }}
            visibleFrom={
              filters?.length === 1 ? 'sm' : filters?.length > 4 ? 'xl' : 'lg'
            }
          >
            {filters &&
              filters.map((filter) => (
                <AppFlexbox key={filter.key} style={{ flex: 1, maxWidth: 250 }}>
                  <FilterInput
                    key={filter.value}
                    disabled={isLoading}
                    loading={filtersLoading}
                    style={{ flex: 1 }}
                    {...filter}
                  />
                </AppFlexbox>
              ))}
          </AppFlexbox>

          <ScrollArea
            hiddenFrom={
              filters?.length === 1 ? 'sm' : filters?.length > 4 ? 'xl' : 'lg'
            }
            w="100%"
          >
            <AppFlexbox
              style={{
                gap: 5,
                alignSelf: 'end',
                alignItems: 'center'
              }}
            >
              {filters && (
                <>
                  <MenuContent
                    control={
                      <Button
                        radius="xl"
                        rightSection={<Plus size={18} />}
                        variant="outline"
                      >
                        Filters
                      </Button>
                    }
                    position="bottom-start"
                    width={300}
                  >
                    <AppStack style={{ gap: 5, padding: 5 }}>
                      {filters.map((filter) => (
                        <AppFlexbox key={filter.key}>
                          <FilterInput
                            key={filter.value}
                            disabled={isLoading}
                            loading={filtersLoading}
                            style={{ flex: 1 }}
                            {...filter}
                          />
                        </AppFlexbox>
                      ))}
                    </AppStack>
                  </MenuContent>
                  <AppFlexbox style={{ gap: 5 }}>
                    {filters
                      .filter((f) => f.value)
                      .map((f) => {
                        const option = f.data?.find((d) => d.value === f.value);
                        return (
                          <Badge
                            key={f.key}
                            rightSection={
                              (f.clearable || f.type !== 'select') && (
                                <X
                                  onClick={() => f.onChange('', true)}
                                  size={18}
                                  style={{ cursor: 'pointer' }}
                                />
                              )
                            }
                            style={{ height: 32, textTransform: 'none' }}
                            variant="light"
                          >
                            {option?.label || f.value}
                          </Badge>
                        );
                      })}
                  </AppFlexbox>
                </>
              )}
            </AppFlexbox>
          </ScrollArea>

          <AppStack
            style={{
              gap: 0,
              justifyContent: 'space-between',
              alignItems: 'end'
            }}
          >
            {isLoading ? (
              <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                <Loader color="grey" size={15} />
                <AppText style={{ color: 'grey', fontSize: 14 }}>
                  results
                </AppText>
              </AppFlexbox>
            ) : (
              <AppText
                style={{ color: 'grey', fontSize: 14, textWrap: 'balance' }}
              >
                Showing {rows.length} of{' '}
                {singularPluralFormat(TOTAL_ITEM_COUNT, 'result', 'results')}
              </AppText>
            )}

            <AppFlexbox
              style={{
                flex: 1,
                justifyContent: 'flex-end',
                placeItems: 'end',
                gap: 10,
                maxWidth: 350
              }}
            >
              <AppFlexbox
                style={{
                  flex: 1,
                  justifyContent: 'flex-end',
                  placeItems: 'center',
                  gap: 10,
                  minWidth: 300
                }}
              >
                {sortFilter && (
                  <>
                    <AppFlexbox style={{ flex: 1 }}>
                      <FilterInput
                        key={sortFilter.value}
                        disabled={isLoading}
                        loading={filtersLoading}
                        style={{ flex: 1 }}
                        {...sortFilter}
                        onChange={(value) => {
                          if (onChangeSortBy) {
                            onChangeSortBy(value, false);
                          }
                          sortFilter.onChange(value, false);
                        }}
                      />
                    </AppFlexbox>
                    {onChangeSortBy && (
                      <Tooltip
                        label="Order By"
                        position="bottom"
                        withArrow
                        withinPortal
                      >
                        <AppStack>
                          <ActionableIcon
                            color="dodgerblue"
                            disabled={isLoading}
                            onClick={() =>
                              onChangeSortBy(sortBy, !isDescendingSort)
                            }
                            radius="xl"
                            style={{ background: '#DEE2E6' }}
                            variant="subtle"
                          >
                            {isDescendingSort ? <ArrowDown /> : <ArrowUp />}
                          </ActionableIcon>
                        </AppStack>
                      </Tooltip>
                    )}
                  </>
                )}
                {onRefresh && (
                  <Tooltip
                    label="Refresh"
                    position="bottom"
                    withArrow
                    withinPortal
                  >
                    <AppStack>
                      <ActionableIcon
                        color="dodgerblue"
                        disabled={isLoading}
                        onClick={onRefresh}
                        radius="xl"
                        style={{ background: '#DEE2E6' }}
                        variant="subtle"
                      >
                        <Refresh />
                      </ActionableIcon>
                    </AppStack>
                  </Tooltip>
                )}
              </AppFlexbox>
            </AppFlexbox>
          </AppStack>
        </AppFlexbox>

        {alertState.current
          .sort((a, b) => a.key - b.key)
          .map((alert, i) => (
            <Alert
              key={alert.key}
              color={alert.color}
              icon={alert.icon}
              onClose={() =>
                setAlertState({
                  ...alertState,
                  current: [
                    ...alertState.current.filter((a) => a.key !== alert.key)
                  ]
                })
              }
              style={{
                padding: 10,
                borderRadius: 0,
                borderTop: i > 0 ? '0.01rem solid #DEE2E6' : 'none'
              }}
              styles={{ closeButton: { marginTop: 1 } }}
              variant="filled"
              withCloseButton
            >
              <AppStack style={{ gap: 10 }}>
                <AppFlexbox
                  onClick={
                    alert.additionalErrors
                      ? () =>
                          setAlertState({
                            ...alertState,
                            current: [
                              ...alertState.current.filter(
                                (a) => a.key !== alert.key
                              ),
                              {
                                ...alert,
                                showAdditionalErrors: !alert.showAdditionalErrors
                              }
                            ]
                          })
                      : null
                  }
                  style={{
                    flex: 1,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: alert.additionalErrors ? 'pointer' : 'normal'
                  }}
                >
                  <AppText style={{ fontSize: 14 }}>{alert.message}</AppText>
                  {alert.additionalErrors.length > 0 && (
                    <Text
                      onClick={() =>
                        setAlertState({
                          ...alertState,
                          current: [
                            ...alertState.current.filter(
                              (a) => a.key !== alert.key
                            ),
                            {
                              ...alert,
                              showAdditionalErrors: !alert.showAdditionalErrors
                            }
                          ]
                        })
                      }
                      style={{ fontSize: 14, marginRight: 20 }}
                    >
                      {alert.showAdditionalErrors ? 'Hide Info' : 'View Info'}
                    </Text>
                  )}
                </AppFlexbox>
                {alert.showAdditionalErrors && (
                  <List>
                    {alert.additionalErrors.map((error, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <List.Item key={index}>{error}</List.Item>
                    ))}
                  </List>
                )}
              </AppStack>
            </Alert>
          ))}

        {columns.length > 0 && (
          <AppStack style={{ overflow: 'auto', textWrap: 'nowrap', flex: 1 }}>
            <Table horizontalSpacing="xl" striped verticalSpacing="sm">
              <Table.Thead
                style={{
                  position: 'sticky',
                  top: 0,
                  backgroundColor: '#FFF',
                  zIndex: 99,
                  boxShadow: 'inset 0 1px 0 #DEE2E6, inset 0 -1px 0 #DEE2E6'
                }}
              >
                <Table.Tr hiddenFrom="sm" style={{ borderBottom: 'none' }}>
                  <Table.Th style={{ padding: '12px 10px' }}>
                    <AppFlexbox
                      style={{
                        gap: 5,
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      {isLoading ? (
                        <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                          <Loader color="grey" size={15} />
                          <AppText style={{ color: 'grey', fontSize: 14 }}>
                            results
                          </AppText>
                        </AppFlexbox>
                      ) : (
                        <AppText
                          style={{
                            color: 'grey',
                            fontSize: 14,
                            textWrap: 'balance'
                          }}
                        >
                          Showing {rows.length} of{' '}
                          {singularPluralFormat(
                            TOTAL_ITEM_COUNT,
                            'result',
                            'results'
                          )}
                        </AppText>
                      )}
                      <AppFlexbox
                        style={{
                          alignItems: 'center',
                          flex: 1,
                          justifyContent: 'flex-end'
                        }}
                      >
                        {sortFilter && (
                          <>
                            <AppFlexbox
                              style={{
                                flex: 1,
                                maxWidth: rs([200, 300], mqIndex)
                              }}
                            >
                              <FilterInput
                                key={sortFilter.value}
                                disabled={isLoading}
                                loading={filtersLoading}
                                style={{ flex: 1 }}
                                {...sortFilter}
                                onChange={(value) => {
                                  if (onChangeSortBy) {
                                    onChangeSortBy(value, false);
                                  }
                                  sortFilter.onChange(value, false);
                                }}
                              />
                            </AppFlexbox>
                            {onChangeSortBy && (
                              <ActionableIcon
                                color="dodgerblue"
                                disabled={isLoading}
                                onClick={() =>
                                  onChangeSortBy(sortBy, !isDescendingSort)
                                }
                                radius="xl"
                                style={{ background: '#DEE2E6' }}
                                variant="subtle"
                              >
                                {isDescendingSort ? <ArrowDown /> : <ArrowUp />}
                              </ActionableIcon>
                            )}
                          </>
                        )}
                      </AppFlexbox>
                    </AppFlexbox>
                  </Table.Th>
                </Table.Tr>
                <Table.Tr style={{ border: 'none' }} visibleFrom="sm">
                  {columns.map((c, i) => {
                    const isOrderBySelected = sortBy === c.value;
                    return (
                      <Table.Th key={c.value} style={{ maxWidth: 200 }}>
                        <AppFlexbox
                          onClick={() => {
                            if (c.sortable) {
                              onChangeSortBy(
                                c.value,
                                isOrderBySelected ? !isDescendingSort : false
                              );
                            }
                          }}
                          style={{
                            cursor: c.sortable ? 'pointer' : 'normal',
                            gap: 10,
                            alignItems: 'center'
                          }}
                        >
                          {onSelect && i === 0 && (
                            <Tooltip
                              label={
                                allRowsSelected ? 'Unselect All' : 'Select All'
                              }
                            >
                              <Checkbox
                                checked={allRowsSelected}
                                disabled={isLoading}
                                onChange={() => {
                                  if (allRowsSelected) {
                                    onSelect([]);
                                  }
                                  else {
                                    onSelect(rows.map((r) => r.key));
                                  }
                                }}
                                onClick={(e) => e.stopPropagation()}
                              />
                            </Tooltip>
                          )}
                          {c.label}
                          {isOrderBySelected &&
                            (isDescendingSort ? (
                              <ArrowDown color="dodgerblue" size={20} />
                            ) : (
                              <ArrowUp color="dodgerblue" size={20} />
                            ))}
                        </AppFlexbox>
                      </Table.Th>
                    );
                  })}
                  {!isLoading && onAction && TOTAL_ITEM_COUNT > 0 && (
                    <Table.Th />
                  )}
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody hiddenFrom="sm">
                {isLoading ? (
                  Array.from(Array(25)).map((x, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Table.Tr key={i}>
                      <Table.Td style={{ padding: '12px 10px' }}>
                        <AppStack style={{ gap: 5 }}>
                          <Skeleton height={10} width="100%" />
                          <Skeleton height={10} width="80%" />
                        </AppStack>
                      </Table.Td>
                    </Table.Tr>
                  ))
                ) : TOTAL_ITEM_COUNT === 0 ? (
                  <Table.Tr>
                    <Table.Td
                      colSpan={columns.length + 1}
                      style={{ padding: '12px 10px' }}
                    >
                      <AppStack
                        style={{
                          alignItems: 'center',
                          textAlign: 'center',
                          padding: '30px 0px'
                        }}
                      >
                        <AppText style={{ fontWeight: 500 }}>
                          {emptyMessage || 'No Items Available'}
                        </AppText>
                        {emptyActionButton}
                      </AppStack>
                    </Table.Td>
                  </Table.Tr>
                ) : (
                  (noSort ? rows : rows.sort(sortRows)).map((r, i) => (
                    <Table.Tr key={r.key}>
                      <Table.Td style={{ padding: '12px 10px' }}>
                        <AppFlexbox style={{ flex: 1, alignItems: 'center' }}>
                          <AppFlexbox
                            onClick={(e) => {
                              e.stopPropagation();
                              onSelectTableRow(r.key);
                            }}
                            style={{
                              flex: 1,
                              alignItems: 'start',
                              gap: 10,
                              ...onSelecStyles
                            }}
                          >
                            {r.columns[0].src ? (
                              <AppImage
                                height={40}
                                src={r.columns[0].src}
                                width={40}
                              />
                            ) : (
                              !!r.columns[0].icon && r.columns[0].icon
                            )}
                            <AppStack
                              style={{
                                flex: 1,
                                gap: 0,
                                textWrap: 'wrap',
                                alignSelf: 'center'
                              }}
                            >
                              {renderTableColumnInfo(
                                r.key,
                                r.columns[0],
                                0,
                                true,
                                true
                              )}
                              {r.columns
                                .filter((c) => c.showOnMobile)
                                .map((c) => (
                                  <React.Fragment key={c.key}>
                                    {renderTableColumnInfo(
                                      r.key,
                                      c,
                                      null,
                                      true
                                    )}
                                  </React.Fragment>
                                ))}
                            </AppStack>
                          </AppFlexbox>

                          {onAction &&
                            r.actions &&
                            renderTableRowMenuActions(r)}
                        </AppFlexbox>
                      </Table.Td>
                    </Table.Tr>
                  ))
                )}
                <Table.Tr
                  ref={endOfPageLoaderMobile}
                  style={{ display: showEndPageLoader ? 'table-row' : 'none' }}
                >
                  <Table.Td colSpan={columns.length + 1}>
                    <AppText
                      style={{
                        textAlign: 'center',
                        padding: '30px 0px',
                        fontWeight: 500
                      }}
                    >
                      <Loader color="dark" />
                    </AppText>
                  </Table.Td>
                </Table.Tr>
              </Table.Tbody>
              <Table.Tbody visibleFrom="sm">
                {isLoading ? (
                  Array.from(Array(25)).map((x, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Table.Tr key={i}>
                      {columns.map((c, index) => (
                        <Table.Td key={c.value}>
                          <AppFlexbox style={{ alignItems: 'center', gap: 10 }}>
                            {onSelect && index === 0 && (
                              <Checkbox
                                disabled
                                styles={{ input: { cursor: 'pointer' } }}
                              />
                            )}
                            <Skeleton height={12} width="100%" />
                          </AppFlexbox>
                        </Table.Td>
                      ))}
                    </Table.Tr>
                  ))
                ) : TOTAL_ITEM_COUNT === 0 ? (
                  <Table.Tr>
                    <Table.Td colSpan={columns.length + 1}>
                      <AppStack
                        style={{
                          alignItems: 'center',
                          textAlign: 'center',
                          padding: '30px 0px'
                        }}
                      >
                        <AppText style={{ fontWeight: 500 }}>
                          {emptyMessage || 'No Items Available'}
                        </AppText>
                        {emptyActionButton}
                      </AppStack>
                    </Table.Td>
                  </Table.Tr>
                ) : (
                  (noSort ? rows : rows.sort(sortRows)).map((r) => (
                    <Table.Tr key={r.key}>
                      {r.columns.map((c, i) => (
                        <Table.Td
                          key={c.key}
                          onClick={(e) => {
                            e.stopPropagation();
                            onSelectTableRow(r.key);
                          }}
                          style={onSelecStyles}
                        >
                          {renderTableColumnInfo(r.key, c, i)}
                        </Table.Td>
                      ))}
                      {onAction && (
                        <Table.Td
                          style={{ textAlign: 'end', paddingRight: 40 }}
                        >
                          {r.actions && renderTableRowMenuActions(r)}
                        </Table.Td>
                      )}
                    </Table.Tr>
                  ))
                )}
                <Table.Tr
                  ref={endOfPageLoader}
                  style={{ display: showEndPageLoader ? 'table-row' : 'none' }}
                >
                  <Table.Td colSpan={columns.length + 1}>
                    <AppText
                      style={{
                        textAlign: 'center',
                        padding: '30px 0px',
                        fontWeight: 500
                      }}
                    >
                      <Loader color="dark" />
                    </AppText>
                  </Table.Td>
                </Table.Tr>
              </Table.Tbody>
            </Table>
          </AppStack>
        )}
      </AppStack>
    );
  },
  isTableRenderSameAsBefore
);

TableView.propTypes = {
  alerts: PropTypes.array,
  columns: PropTypes.array,
  emptyActionButton: PropTypes.node,
  emptyMessage: PropTypes.string,
  filters: PropTypes.array,
  filtersLoading: PropTypes.bool,
  isDescendingSort: PropTypes.bool,
  isLoading: PropTypes.bool,
  lastUpdated: PropTypes.number,
  noSort: PropTypes.bool,
  onAction: PropTypes.func,
  onChangeSortBy: PropTypes.func,
  onFetchMore: PropTypes.func,
  onRefresh: PropTypes.func,
  onSelect: PropTypes.func,
  rows: PropTypes.array,
  selectedRows: PropTypes.array,
  sortBy: PropTypes.string,
  sortFilter: PropTypes.object,
  tableTitle: PropTypes.string,
  totalCount: PropTypes.number
};

export default TableView;
