import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { Context as RegistrationAdminAdminContext } from '../../../providers/RegistrationAdminDashboardProvider';
import AppCard from '../../common/AppCard';
import TableView from '../../common/TableView';
import { useModalState, useSortByFilter } from '../../../helpers/hooks';
import { formatUtcDate } from '../../../helpers/format';
import WaitlistModal from '../waitlist/WaitlistModal';
import ParticipantModal from '../participants/ParticipantModal';
import WaitlistEmailModal from '../waitlist/WaitlistEmailModal';
import ColoredAvatar from '../../common/ColoredAvatar';

const WAITLIST_ACTIONS_ENUM = {
  viewRegistrant: 'VIEW',
  emailUser: 'EMAIL',
  emailWaitlist: 'BULK_EMAIL',
  sendReservation: 'APPROVE',
  delete: 'REMOVE'
};

const WAITLIST_ACTIONS = [
  {
    label: 'View',
    value: WAITLIST_ACTIONS_ENUM.viewRegistrant,
    alwaysShow: true
  },
  {
    label: 'Email Contact',
    value: WAITLIST_ACTIONS_ENUM.emailUser,
    alwaysShow: true
  },
  {
    label: 'Email Waitlist',
    value: WAITLIST_ACTIONS_ENUM.emailWaitlist,
    alwaysShow: true
  },
  {
    label: 'Send Reservation',
    value: WAITLIST_ACTIONS_ENUM.sendReservation,
    showWhenPending: true
  },
  {
    label: 'Resend Reservation',
    value: WAITLIST_ACTIONS_ENUM.sendReservation,
    showWhenInProgress: true
  },
  {
    label: 'Remove',
    value: WAITLIST_ACTIONS_ENUM.delete,
    alwaysShow: true
  }
];

const TABLE_COLUMNS = [
  {
    label: 'Name',
    value: 'name',
    sortable: true
  },
  {
    label: 'Order',
    value: 'order',
    sortable: true
  },
  {
    label: 'Division',
    value: 'division',
    sortable: true
  },
  {
    label: 'Form',
    value: 'form',
    sortable: true
  },
  {
    label: 'Status',
    value: 'status',
    sortable: true
  }
];

const LeagueDivisionWaitlistView = ({
  isLoading,
  modalAction,
  filterState,
  filters
}) => {
  const { state: dashboardState } = useContext(RegistrationAdminAdminContext);
  const { state, fetchAdminWaitlistRegistrants } = useContext(
    RegistrationAdminContext
  );
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const {
    sortValue,
    isDescendingSort,
    onChangeSortBy,
    customFilterData
  } = useSortByFilter(TABLE_COLUMNS, { defaultSort: 'order' });

  useEffect(() => {
    if (modalAction === WAITLIST_ACTIONS_ENUM.emailWaitlist) {
      onOpenModal(modalAction);
    }
  }, [modalAction]);

  return (
    <AppCard style={{ flex: 1, padding: 0, height: '100%' }}>
      <TableView
        columns={TABLE_COLUMNS}
        emptyMessage="No Registrants Available"
        filters={filters}
        filtersLoading={state.regAssociation.loading}
        isDescendingSort={isDescendingSort}
        isLoading={isLoading}
        lastUpdated={state.regWaitlistRegistrants.lastUpdated}
        onAction={(action, item) => {
          const registrant = state.regWaitlistRegistrants.value.find(
            (s) => s.pkRegWaitlistPerson === item.key
          );
          if (action === WAITLIST_ACTIONS_ENUM.emailUser) {
            window.open(`mailto:${registrant.regPerson.user.email}`, '_blank');
          }
          else {
            onOpenModal(action, registrant);
          }
        }}
        onChangeSortBy={onChangeSortBy}
        onRefresh={() =>
          fetchAdminWaitlistRegistrants({
            pkRegAssociation:
              dashboardState.regAssociation.value.pkRegAssociation
          })
        }
        rows={state.regWaitlistRegistrants.value
          .filter(
            (i) =>
              !i.deleted &&
              (!filterState.pkRegAssociationSeason ||
                i.regAssociationDivisionForm.regAssociationDivision.fkRegAssociationSeason.toString() ===
                  filterState.pkRegAssociationSeason) &&
              (!filterState.pkRegAssociationDivision ||
                i.regAssociationDivisionForm.regAssociationDivision.pkRegAssociationDivision.toString() ===
                  filterState.pkRegAssociationDivision) &&
              (!filterState.regFormUuid ||
                i.regAssociationDivisionForm.regForm.uuid ===
                  filterState.regFormUuid) &&
              (!filterState.regPersonName ||
                `${i.regPerson.firstName}${i.regPerson.lastName}`
                  .toLowerCase()
                  .includes(
                    filterState.regPersonName.toLowerCase().replace(' ', '')
                  ))
          )
          .map((p) => {
            const activeReservation = p?.waitlistReservations
              .filter((r) => !r.deleted)
              .sort(
                (a, b) => new Date(a.expiryDate) - new Date(b.expiryDate)
              )[0];

            const expiryDate = activeReservation
              ? formatUtcDate(activeReservation.expiryDate)
              : null;
            const hasExpired = activeReservation
              ? activeReservation.deleted || new Date() >= new Date(expiryDate)
              : false;
            let expiryTimeHours = activeReservation
              ? dayjs(expiryDate).diff(dayjs(new Date()), 'hour')
              : 0;
            const expiryTimeMinutes = activeReservation
              ? Math.ceil(
                  dayjs(expiryDate).diff(dayjs(new Date()), 'minute', true)
                )
              : 0;
            const expiryTimeDays = Math.ceil(expiryTimeHours / 24);
            expiryTimeHours -= Math.floor(expiryTimeHours / 24) * 24;

            const badgeContent = !activeReservation
              ? 'Waiting'
              : hasExpired
              ? 'Expired'
              : `Expires in ${
                  expiryTimeDays > 1
                    ? `${expiryTimeDays} ${
                        expiryTimeDays === 1 ? 'day' : 'days'
                      }`
                    : expiryTimeHours > 0
                    ? `${expiryTimeHours} ${
                        expiryTimeHours === 1 ? 'hour' : 'hours'
                      }`
                    : expiryTimeMinutes > 0 &&
                      `${expiryTimeMinutes} ${
                        expiryTimeMinutes === 1 ? 'minute' : 'minutes'
                      }`
                }`;
            return {
              key: p.pkRegWaitlistPerson,
              actions: WAITLIST_ACTIONS.filter(
                (f) =>
                  f.alwaysShow ||
                  (activeReservation ? f.showWhenInProgress : f.showWhenPending)
              ),
              columns: [
                {
                  key: 1,
                  label: `${p.regPerson.firstName} ${p.regPerson.lastName}`,
                  weight: 500,
                  subLabel: p.regPerson.user.email,
                  onClick: () =>
                    onOpenModal(WAITLIST_ACTIONS_ENUM.viewRegistrant, p),
                  onSubLabelClick: () =>
                    window.open(`mailto:${p.regPerson.user.email}`),
                  icon: (
                    <ColoredAvatar
                      name={`${p.regPerson.firstName} ${p.regPerson.lastName}`}
                      uniqueId={p.regPerson.user.email}
                    />
                  )
                },
                {
                  key: 0,
                  label: p.order,
                  sortValueType: 'number',
                  showOnMobile: true
                },
                {
                  key: 2,
                  label:
                    p.regAssociationDivisionForm.regAssociationDivision.name,
                  subLabel:
                    p.regAssociationDivisionForm.regAssociationDivision
                      .regAssociationSeason.name
                },
                {
                  key: 3,
                  label: p.regAssociationDivisionForm.regForm.name,
                  showOnMobile: true
                },
                {
                  key: 4,
                  label: badgeContent,
                  color: !activeReservation
                    ? 'blue'
                    : hasExpired
                    ? 'red'
                    : 'green',
                  showOnMobile: true
                }
              ]
            };
          })}
        sortBy={sortValue}
        sortFilter={customFilterData}
        tableTitle="Waitlist"
      />

      <WaitlistModal
        isAdmin
        isOpen={
          modalState.isOpen &&
          modalState.action !== WAITLIST_ACTIONS_ENUM.viewRegistrant &&
          modalState.action !== WAITLIST_ACTIONS_ENUM.emailWaitlist
        }
        onClose={onCloseModal}
        regWaitlistPerson={modalState.item}
        showDelete={modalState.action === WAITLIST_ACTIONS_ENUM.delete}
      />
      <ParticipantModal
        isOpen={
          modalState.isOpen &&
          modalState.action === WAITLIST_ACTIONS_ENUM.viewRegistrant
        }
        onClose={onCloseModal}
        participant={modalState.item?.regPerson}
        readOnly
      />
      <WaitlistEmailModal
        fkRegAssociationDivision={filterState.pkRegAssociationDivision}
        fkRegAssociationSeason={filterState.pkRegAssociationSeason}
        isOpen={
          modalState.isOpen &&
          modalState.action === WAITLIST_ACTIONS_ENUM.emailWaitlist
        }
        onClose={onCloseModal}
        pkRegAssociation={dashboardState.regAssociation.value?.pkRegAssociation}
        regWaitlistRegistrants={state.regWaitlistRegistrants.value}
      />
    </AppCard>
  );
};

LeagueDivisionWaitlistView.propTypes = {
  filterState: PropTypes.object,
  filters: PropTypes.array,
  isLoading: PropTypes.bool,
  modalAction: PropTypes.string,
  regAssociation: PropTypes.object
};

export default LeagueDivisionWaitlistView;
